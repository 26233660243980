import { Image } from "./image";
import React from "react";
import { useTranslation } from "react-i18next";

export const Gallery = (props) => {
  const {t,i18n}=useTranslation()
  const heb_font_titel = {
    fontSize: i18n.language === 'he' ? '255%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '700' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,

  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2 style={heb_font_titel}> {t('Gallery')}</h2>
          <p>
           {t('gallery_titel')}
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
