import { GrLanguage } from "react-icons/gr";
import './style.css'
import React from "react";
import { useTranslation } from "react-i18next";
import logo_nobg from '../assets/logo_nav.png'


export const Navigation = (props) => {
  
  const {t,i18n}=useTranslation()
  const heb_font = {
    fontSize: i18n.language === 'he' ? '130%' : 'inherit',
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : 'inherit',
    fontWeight: i18n.language === 'he' ? '300' : 'inherit'
  };
  

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'he' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          <img className="logo_nav" src={logo_nobg} alt="codekit logo" style={{}}/>
          <a className="navbar-brand page-scroll " href="#page-top" aria-label="Home">
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll" style={heb_font}>
                {t('Features')}
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll" style={heb_font}>
                {t('About')}
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll" style={heb_font}>
                {t('Services')}
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll" style={heb_font}>
                {t('Gallery')}
              </a>
            </li>
           
            <li>
              <a href="#team" className="page-scroll" style={heb_font}>
                {t('Team')}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll"  style={heb_font}>
                {t('Contact')}
              </a>
            </li>
            <li>
             
              <div  className="toggle" onClick={toggleLanguage} >
              <GrLanguage />{t('toggleLanguage')}
              </div>
            </li>
          </ul>
          
        </div>
        
      </div>
      
    </nav>
  );
};

/* <div className="toggle" onClick={toggleLanguage}>
              <GrLanguage />{t('toggleLanguage')}
              </div> */