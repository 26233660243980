import React from "react";
import { useTranslation } from "react-i18next";

export const Team = (props) => {
  const {t,i18n}=useTranslation()
  const Team_members = t('Team_members', { returnObjects: true });

  const heb_style_p={
    direction: i18n.language === 'he' ? 'rtl' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontSize: i18n.language === 'he' ? '130%' : undefined,
  }

  const heb_font = {
    fontSize: i18n.language === 'he' ? '260%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '700' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,   
  };

  const heb_style_t={
    direction: i18n.language === 'he' ? 'rtl' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontSize: i18n.language === 'he' ? '108%' : undefined,
  }

  const heb_style_j={
    direction: i18n.language === 'he' ? 'rtl' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontSize: i18n.language === 'he' ? '120%' : undefined,
  }



  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2 style={heb_font}>{t('team_titel')}</h2>
          <p style={heb_style_p}>
            {t('teem-text')}
          </p>
        </div>
        <div id="row">
          {Team_members
            ? Team_members.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4 style={heb_style_j}>{d.name}</h4>
                      <p style={heb_style_t}>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
