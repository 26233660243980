import React from "react";
import { useTranslation } from "react-i18next";
import './style.css'

export const About = (props) => {
  const {t,i18n}=useTranslation()
  
  const heb_font = {
    fontSize: i18n.language === 'he' ? '120%' : 'inherit',
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : 'inherit',
    fontWeight: i18n.language === 'he' ? '300' : 'inherit'
  };

  const heb_font_titel = {
    fontSize: i18n.language === 'he' ? '250%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '700' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,   
  };

  const heb_font_h2 = {
    fontSize: i18n.language === 'he' ? '200%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '700' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,   
  };
  console.log([t('why')]);
  const whyData1 = Array.from({ length: 4 }, (_, i) => t(`Why.${i}`));
  const whyData2 = Array.from({ length: 4 }, (_, i) => t(`Why2.${i}`));



  return (
    <div id="about" className={i18n.language === 'he' ? 'rtl ' : 'ltr'} >
      <div className="container ">
        <div className="row ">
          <div className="col-xs-12 col-md-6 ">
            {" "}
            <img src="img/about2.jpg" className="img-responsive" alt="ba" />
          </div>
          <div className="col-xs-12 col-md-6" >
            <div className="about-text" >
              <h2 style={heb_font_titel}>{t('abotustitel')} </h2>
              <p style={heb_font}>{ t('aboutus') ? t('aboutus') : "loading..." }</p>
              <h3 style={heb_font_h2} > {t('Why Choose Us')}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    { whyData1
                      ? whyData1.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    { whyData2
                      ? whyData2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
