import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { useTranslation } from "react-i18next";
import Popup from "./Popup";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const {t,i18n}=useTranslation()

  const heb_font_titel = {
    fontSize: i18n.language === 'he' ? '255%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '700' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,

  };

  const heb_font = {
    fontSize: i18n.language === 'he' ? '150%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '200' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,   
  };

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    
    emailjs
      .sendForm("service_ikwgi56", "template_mw9ea3n", e.target, "WRe1AxRZRD1p_fgpj")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          setPopupMessage("Your message has been sent successfully!");
          setShowPopup(true);
        },
        (error) => {
          console.log(error.text);
          setPopupMessage("An error occurred, please try again.");
          setShowPopup(true);
        }
      );
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div  className={i18n.language === 'he' ? 'rtl ' : 'ltr'}>
      <div id="contact" className="contactBG">
        <div className="container " >
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2 style={heb_font_titel}>{t('contact1')}</h2>
                <p>
                {t('contact2')}
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={t('contact8')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={t('contact6')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={t('contact9')}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  { t('contact7')}
                </button>
              </form>
              {showPopup && (
        <Popup message={popupMessage} onClose={handleClosePopup} />
      )}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3 style={heb_font}>{t('contact3')}</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> {t('contact4')}
                </span>
                {t('contact4.5') ? t('contact4.5'): "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> {t('contact5')}
                </span>{" "}
                058-6655345
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> {t('contact6')}
                </span>{" "}
                codekitchen10@gmail.com
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
        
                  <li>
                    <a href="https://www.linkedin.com/company/code-ki">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};
