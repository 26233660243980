import React from "react";

export const Image = ({ title, largeImage, smallImage }) => {
  const titleimage = title ? title : "next" 
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <a href={largeImage} title={title} alt={titleimage} data-lightbox-gallery="gallery1">
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img src={smallImage} className="img-responsive" alt={titleimage} />{" "}
        </a>{" "}
      </div>
    </div>
  );
};
