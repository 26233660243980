import React from "react";
import { useTranslation } from "react-i18next";


export const Services = (props) => {

  const {t,i18n}=useTranslation()

  const heb_font_titel = {
    fontSize: i18n.language === 'he' ? '275%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '700' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,   
  };

  const heb_style ={
    direction: i18n.language === 'he' ? 'rtl' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontSize: i18n.language === 'he' ? '300%' : undefined,
  }

  const heb_style_p={
    direction: i18n.language === 'he' ? 'rtl' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontSize: i18n.language === 'he' ? '140%' : undefined,
  }

  
  const heb_style_p2={
    direction: i18n.language === 'he' ? 'rtl' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontSize: i18n.language === 'he' ? '%' : undefined,
  }


  const services = t('Services_text', { returnObjects: true });


  

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2 style={heb_font_titel}>{t('ourService')}</h2>
          <p style={heb_style_p}>{t('Services_dis')}</p>
        </div>
        <div className="row">
        {services
        ? services.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4" style={heb_style}>
              <i className={d.icon}></i>
              <div className="service-desc">
                <h3 style={heb_style_p2}>{d.name}</h3>
                <p >{d.text}</p>
              </div>
            </div>
          ))
        : null}
        </div>
      </div>
    </div>
  );
};
