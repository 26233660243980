import React from "react";
import { useTranslation } from "react-i18next";

export const Features = (props) => {

  const {t,i18n}=useTranslation()

  const features = t('Features_text', { returnObjects: true });

  const heb_style_p={
    direction: i18n.language === 'he' ? 'rtl' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontSize: i18n.language === 'he' ? '108%' : undefined,
  }


  const heb_font = {
    fontSize: i18n.language === 'he' ? '290%' : undefined,
    fontFamily: i18n.language === 'he' ? 'rubik, Arial, sans-serif' : undefined,
    fontWeight: i18n.language === 'he' ? '700' : undefined,
    direction: i18n.language === 'he' ? 'rtl' : undefined,   
  };


  



  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2 style={heb_font}>{t('Features')}</h2>
        </div>
        <div className="row">
          {features
            ? features.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p style={heb_style_p}>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
